import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { StaticQuery, graphql, Link } from "gatsby"

import theme from '../styles/theme'
import { media } from '../styles/utils'

const CtaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.medium`
    flex-direction: column;
    text-align: center;
  `}
`;

const MainText = styled.div`
  color: ${props => props.theme.color.white};
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 36px;
  line-height: 1.3;
`;

const BannerLink = styled(Link)`
  margin-left: 20px;

  ${media.medium`
    margin-left: 0;
    margin-top: 40px;
  `}
`;

const BannerSimple = (props) => (
    <StaticQuery
    query={graphql`
      query BannerSimpleQuery {
        allContentfulSimpleBanners {
          edges {
            node {
              id
              text {
                text
              }
              buttonUrl
              buttonText
            }
          }
        }
      }
    `}
    render={data => {
      
      return (
        <>
        {data.allContentfulSimpleBanners.edges.map(({node: banner}) => {
          if(banner.id === props.id){
            
            return (
              <div key={banner.id} className="cta-one theme-bg ptb-75">
                <div className="container">
                  <div className="row">
                    <div className="col-xs-12">
                      <CtaContainer className="cta-content clearfix">
                        <div className="">
                          <MainText>{banner.text.text}</MainText>
                        </div>
                        <BannerLink to={`${banner.buttonUrl}`} className="btn">{banner.buttonText}</BannerLink>
                        
                      </CtaContainer>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        })}
        </>
      )}}
    />
  )

export default BannerSimple
