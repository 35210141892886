import React, { Component } from 'react'
import styled from 'styled-components'

const GMap = styled.iframe`
  width: 100%;
  height: 400px;
`;

export default class Map extends Component {
  render() {
    return (
      <GMap src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3142.5935858079665!2d-84.44637178420052!3d38.03325317971323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88425000aff8e6c7%3A0xc27f228a84acfcdf!2sKPC+Architectural+Products%2C+Inc.!5e0!3m2!1sen!2sus!4v1546298015140" frameBorder="0" allowFullScreen />
    )
  }
}
