import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {rgba} from 'polished'

/*
  ⚠️ This is an example of a contact form powered with Netlify form handling.
  Be sure to review the Netlify documentation for more information:
  https://www.netlify.com/docs/form-handling/
*/

const Modal = styled.div`
  background: white;
  padding: 2em;
  border-radius: 2px;
  position: fixed;
  min-width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 99;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  transition: 0.2s all;
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    min-width: inherit;
    max-width: 400px;
  }
  p {
    line-height: 1.6;
    margin: 0 0 2em 0;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.color.black && rgba(props.theme.color.black, 0.3)};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

const Button = styled.div`
  background: ${props => props.theme.colors.base};
  font-size: 1em;
  display: inline-block;
  margin: 0 auto;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  padding: 1em;
  border-radius: 2px;
  text-decoration: none;
  transition: 0.2s;
  z-index: 99;
  &:focus {
    outline: none;
  }
  &:hover {
    background: ${props => props.theme.colors.highlight};
  }
`

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      tel: '',
      message: '',
      showModal: false,
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...this.state }),
    })
      .then(this.handleSuccess)
      .catch(error => alert(error))
    event.preventDefault()
  }

  handleSuccess = () => {
    this.setState({
      name: '',
      email: '',
      tel: '',
      message: '',
      showModal: true,
    })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <form
        name="contact"
        onSubmit={this.handleSubmit}
        data-netlify="true"
        data-netlify-honeypot="bot"
        // overlay={this.state.showModal}
        onClick={this.closeModal}
        className="custom-input contact_form"
      >
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot" onChange={this.handleInputChange} />
          </label>
        </p>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <input
              name="name"
              type="text"
              placeholder="Full Name"
              value={this.state.name}
              onChange={this.handleInputChange}
              required
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <input
              name="tel"
              type="text"
              placeholder="Phone Number"
              value={this.state.tel}
              onChange={this.handleInputChange}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12">
            <input
              name="email"
              type="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.handleInputChange}
              required
            />
          </div>
        </div>
        <textarea
          name="message"
          type="text"
          placeholder="Message"
          value={this.state.message}
          onChange={this.handleInputChange}
          required
        />
        <button name="submit" className="btn theme-btn mt-15" type="submit">
          Send Message
        </button>

        <Overlay visible={this.state.showModal} />

        <Modal visible={this.state.showModal}>
          <p>
            Thank you for reaching out. I will get back to you as soon as
            possible.
          </p>
          <Button onClick={this.closeModal}>Okay</Button>
        </Modal>
      </form>

      // <form className="custom-input contact_form" id="contact_form" action="contact-form.php" method="post">
      //   <div className="row">
      //     <div className="col-xs-12 col-sm-6">
      //       <input type="text" id="contact_name" name="name" placeholder="Your Name" required />
      //     </div>
      //     <div className="col-xs-12 col-sm-6">
      //       <input type="email" id="contact_email" name="email" placeholder="Your Email" required />
      //     </div>
      //   </div>
      //   <div className="row">
      //     <div className="col-xs-12 col-sm-6">
      //       <input type="text" id="contact_phone" name="phone" placeholder="Phone" />
      //     </div>
      //     <div className="col-xs-12 col-sm-6">
      //       <input type="text" id="contact_website" name="website" placeholder="Website" />
      //     </div>
      //   </div>
      //   <textarea name="message" id="contact_message" rows="2" placeholder="Message" />
      //   <button className="btn theme-btn mt-15" type="submit" name="submit" id="contact_submit" data-complete-text="Well done!">
      //     Send Message
      //   </button>
      // </form>
    )
  }
}

ContactForm.propTypes = {
  data: PropTypes.object,
}

export default ContactForm
