import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import SimpleHero from '../components/SimpleHero'
import ContactForm from '../components/ContactForm'
import SEO from '../components/SEO'
import Map from '../components/Map'
import BannerSimple from '../components/BannerSimple'

const ContactBox = styled.div`
  background-image: url(${(props) => props.bg || ''});
  background-size: cover;
`

const Contact = ({ data }) => {
  const {
    seo,
    textAboveForm,
    simpleBanner,
    box1BackgroundImage,
    box2BackgroundImage,
    box3BackgroundImage,
  } = data.contentfulContactPage
  const postNode = {
    title: `Contact - ${config.siteTitle}`,
  }
  const { phoneNumber, mainEmailAddress, officeAddress } =
    data.allContentfulGlobalVariables.edges[0].node
  const addressHTML = officeAddress.childMarkdownRemark.html.replace(
    '\n',
    '<br />'
  )

  return (
    <Layout>
      <Helmet>
        <title>{`Contact - ${config.siteTitle}`}</title>
      </Helmet>
      {seo && <SEO pagePath="contact" postNode={seo} pageSEO />}

      <SimpleHero headline="Contact Us" pageName="Contact" />

      <div className="contact-area style2 white-bg pt-30 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-8">
              <div className="plr-35">
                <div
                  className="section-title style2 mb-40"
                  dangerouslySetInnerHTML={{
                    __html: textAboveForm.childMarkdownRemark.html,
                  }}
                />
                <div className="contact-form mb-70">
                  <ContactForm />
                </div>
                {/* Contact Form End */}

                {/* <div className="google-map height-410" id="googleMap" /> */}
                <Map />
                {/* Google Map End */}
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 text-center mobi-mb-50">
              <ContactBox
                bg={box1BackgroundImage.file.url}
                className="info-box overlay dark-3 mb-30"
              >
                <div className="d-table relative">
                  <div className="d-table-cell">
                    <h4 className="white-color text-uppercase">
                      Contact address
                    </h4>
                    <span className="v-shape white" />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: addressHTML,
                      }}
                    />
                  </div>
                </div>
              </ContactBox>
              {/* Info Box End */}
              <ContactBox
                bg={box2BackgroundImage.file.url}
                className="info-box overlay theme-overlay mb-30"
              >
                <div className="d-table relative">
                  <div className="d-table-cell">
                    <h4 className="white-color text-uppercase">Let's talk</h4>
                    <span className="v-shape white" />
                    <p>
                      <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                    </p>
                  </div>
                </div>
              </ContactBox>
              {/* Info Box End */}
              <ContactBox
                bg={box3BackgroundImage.file.url}
                className="info-box overlay dark-3"
              >
                <div className="d-table relative">
                  <div className="d-table-cell">
                    <h4 className="white-color text-uppercase">Email Us</h4>
                    <span className="v-shape white" />
                    <p>
                      <a href={`mailto:${mainEmailAddress}`}>
                        {mainEmailAddress}
                      </a>
                    </p>
                  </div>
                </div>
              </ContactBox>
              {/* Info Box End */}
            </div>
          </div>
        </div>
      </div>
      {/* Contact Section End */}
      {/* Call To Action Section Start */}
      <BannerSimple id={simpleBanner.id} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulGlobalVariables(
      filter: { id: { eq: "edec6887-d846-5e51-b03f-0a80904aa7d5" } }
    ) {
      edges {
        node {
          facebookId
          facebookUrl
          twitterHandle
          twitterUrl
          instagramUrl
          phoneNumber
          faxNumber
          mainEmailAddress
          officeAddress {
            childMarkdownRemark {
              html
            }
          }
          id
        }
      }
    }
    contentfulContactPage {
      textAboveForm {
        childMarkdownRemark {
          html
        }
      }
      simpleBanner {
        id
      }
      box1BackgroundImage {
        file {
          url
        }
      }
      box2BackgroundImage {
        file {
          url
        }
      }
      box3BackgroundImage {
        file {
          url
        }
      }
      seo {
        pageTitle
        shareImage {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_noBase64
          }
          ogimg: resize(width: 1800) {
            src
            width
            height
          }
        }
        metaDescription {
          metaDescription
        }
        metaKeywords {
          metaKeywords
        }
      }
    }
  }
`

export default Contact
